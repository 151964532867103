import React from "react"
import styled from "styled-components"
//importación de componentes
import MainLayout from "../components/Layouts/MainLayout"
import Picture2 from "../components/Picture2"
import Intro from "../components/Intro"
import Intro2 from "../components/Intro2"
import Group from "../components/Group"
import useContentful from "../utils/useContentful"
import BoldContentful from "../utils/BoldContentful"
import { Helmet } from "react-helmet"
import color from "../config/colors.json"

const Containercite = styled.div`
display:flex;
flex-direction: row;

`

const Pbig = styled.div`
font-size:50px;
width:5%;
font-family: Roboto;
padding:16px ;
color:#008991;

@media (max-width: 768px)
{
  padding:12px ;
  font-size:40px;
}`


const Cite = styled.div`
width:90% ;
margin-top:24px;
font-weight:500 ;
font-size:18px; 
font-style: italic;
color:#008991;
`

const Autor = styled.div`
margin-top:16px;
font-weight:500 ;
font-size:18px; 
font-style: normal;
color:#008991;
`

const CardIconContainer = styled.div`
width:100% ;
max-width:1440px ;
display:flex ;
flex-direction: row;
justify-content: space-evenly ;

@media (max-width: 679px) {
  flex-direction: column;
  }
`


const CardIcon = styled.div`
display: flex ;
flex-direction: column;
justify-content:center;
align-items: center ;
width:  ${p => `${(~~(100/(p.ancho+1)))}%`};
padding:24px ;
img{
  width:60% ;
  object-fit:cover;
  aspect-ratio: 1;
  margin-bottom:24px;
}
p{
  text-align: center ;
}

@media (max-width: 679px) {
  width:  90%;
  }

`
const TitleIconCard = styled.div`
 font-size:24px;
  font-weight:bold;
  color: ${p => color[p.col] || "#767b8a"};
  margin-bottom: 18px;
`


const Textimagediv = styled.div`
display: flex ;
flex-direction:row ;
padding: 3% 9% 0% 9%;

img{
  width:40%;
  padding: 32px;
  margin-right:32px ;
}
`

const AboutUsEntryId = "1jvg39L1vZ3abBmBpX7rG2";


const Quoteitem  = ({data}) => {
return(
  <Intro title={data.title}>
   {BoldContentful(data.paragraph ,"#767B8A" )} 
  
<Containercite>
    <Pbig>“</Pbig><Cite>{data.quote}</Cite><Pbig>”</Pbig></Containercite>
  
  </Intro>
)
}

const Textimage  = ({data, titulo, descripcion}) => {

console.log("card",data)
  return(
    <Intro title={titulo}>
     {descripcion && <p>{descripcion}</p>}
<CardIconContainer>
   { data.map( item => {
      return (
        <CardIcon ancho={data.length}>
          <img src={item.fields.image.fields.file.url}/>
          <TitleIconCard col={item.fields.titleColor}>{item.fields.title}</TitleIconCard>
          <p>{BoldContentful(item.fields.paragraph)}</p>
        </CardIcon>
      )
    })}
    
    </CardIconContainer>
    </Intro>
  )
  }

const Groupcard  = ({data}) => {

  let team = []

  team = data.cards.map((item ,index)=> {
    return(
        [...team, {
          id:index+1,
          name:item.fields.name,
          position: item.fields.position,
          image: item.fields.image.fields.file.url,
          linkedin: item.fields.linkedin,
        }]
    )
  })


  return(
    <Group team={data.title}  columns={"1fr 1fr 1fr"} members={team} />
  )
  }



export default function AboutUs() {

  const { data: about, fetched } = useContentful({ id: AboutUsEntryId });

 console.log("data",about)

  return (
    
    <>
   <Helmet>
   <link
              rel="alternate"
              hreflang="es-pe"
              href="https://www.datascienceglobalskills.pe/aboutus"
            />
            <link rel="canonical" href="https://www.datascienceglobalskills.pe/aboutus" />

   </Helmet>
   { fetched ? <MainLayout active={2}>
      <Picture2 image={about.fields.hero.fields.file.url}>
        
      </Picture2>

      {
          about.fields.content.map(

            (item) => {
            

              if (item.sys.contentType.sys.id == "quienesSomosTextQuote"){
               
                return(
                  
                  <Quoteitem data = {item.fields}>

                  </Quoteitem>
                )
              }

              else if(item.sys.contentType.sys.id == "quienesSomosTeam"){
              
                return(
                  <Groupcard  data = {item.fields}>

                  </Groupcard>
                )
                
              }

              else if(item.sys.contentType.sys.id == "form"){
                
                return(
                  <Textimage data = {item.fields?.quienesSomosCardIcon} titulo={item.fields?.title} descripcion={item.fields?.description}>

                  </Textimage>
                )
                
              }

            }
          )
      }

      <br></br>
      <br></br>
    </MainLayout>
    :
    <div
     style={{
       width: "100vw",
       height: "100vh",
       display: "flex",
       justifyContent: "center",
       marginTop: "0px",
       padding: "15%",
     }}
   >
     {" "}
     <svg
       version="1.1"
       id="L9"
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 100 100"
       enableBlackground="new 0 0 0 0"
       xmlSpace="preserve"
     >
       <path
         fill="#008991"
         d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
       >
         <animateTransform
           attributeName="transform"
           attributeType="XML"
           type="rotate"
           dur="1s"
           from="0 50 50"
           to="360 50 50"
           repeatCount="indefinite"
         />
       </path>
     </svg>
   </div>
}
   </>
  )
}
