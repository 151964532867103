import React from "react"
import styled from "styled-components"

const DivPicture = styled.div`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    //background-color: rgba(0, 0, 0, 0.7);
    /* background-color: rgba(192, 198, 202, 0.6); */
  }
`
const Text = styled.h1`
  padding-top: 8%;
  padding-left: 2%;
  padding-right: 10%;
  font-weight: bold;
  /* font-size:80%; */
  font-size: 5vw;
  letter-spacing: 5px;
  top: 10px;
  left: 10px;
  color: #fff;
  position: absolute;
`
const Img = styled.img`
  width: 100%;
  /* width: auto; */
  height: auto;
  //opacity: 0.6;
  /* max-height: 600px; */
`

function Picture2(props) {
  return (
    <DivPicture>
      <Img src={props.image} />
      <Text >{props.children}</Text>
    </DivPicture>
  )
}

export default Picture2
