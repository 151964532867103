import React, { useEffect, useState, useRef } from "react"
import ProfileCard from "./ProfileCard"
//import ProfileDetails from "./ProfileDetails"
import "bootstrap/dist/css/bootstrap.min.css"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(200px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;

const Title = styled.h2`
  padding: 0% 9% 2% 0%;
  margin: 0px;
  //margin-bottom: 2rem;
  /*   font-family: "Archivo", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 30px;

  &.animate {
    animation: fade-up 1s;
  }


  @media (max-width: 700px) {
    font-size: 16px;
  }
`
const Colu = styled(Col)`
  position: relative;
  margin-bottom: 3%;
  @media (max-width: 700px) {
    width: 100%;
  }
`

const ContainerCards = styled.div`
  width: 100%;
  
  /*   display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-template-columns: ${props => props.columns || "1fr 1fr 1fr 1fr"};
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  justify-items: center;
  
  a {
    color: #212529;
    text-decoration: none;
    :hover{
      cursor: pointer;
      transform: scale(1.05);
    
    }
    &.animate {
    animation: fade-up 1s;
  }
  }

  a[href="not-found"]{
    cursor: default;
    pointer-events: none;
    &.animate {
    animation: fade-up 1s;
  }
  }
  a[href="#"]:hover {
    transform: none;
    &.animate {
    animation: fade-up 1s;
  }
  }

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export default function Group(props) {
  //const [idcard, setIdcard] = useState()
  


  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 1050) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);




  





  const members = props.members

  console.log('props',props)
  return (
    <Row style={{ padding: "0px 0px 0px 0px", margin: "0px" }}>
      <Colu
        xs="12"
        sm={{ size: 10, offset: 1 }}
        /* onClick={e => {
        setIdcard(e.target.id)
      }} */
      >
        <Title
              className={show ? "animate" : ""}>
          {props.team}
        </Title>
        <ContainerCards columns={props.columns}>
          {members.map((member, index) => {
          const hasLink = member[0].linkedin !== undefined && member[0].linkedin !== null;

            return (
              <a href={hasLink? member[0].linkedin:'not-found'} target="_blank" rel="noreferrer" key={index}
              className={show ? "animate" : ""}>
                <ProfileCard
                  {...member[0]}
                  /* active={idcard} */
                />
              </a>
            )
          })}
          {/* {members.map(member => {
          if (member.id == idcard) {
            return (
              <ProfileDetails
                name={member.name}
                profession={member.profession}
                description={member.description}
                position={member.position}
                active={idcard}
              />
            )
          }
        })} */}
        </ContainerCards>
      </Colu>
    </Row>
  )
}
