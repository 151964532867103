import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(200px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;

const DivCard = styled.div`
  width: 240px;
  height: 260px;
  margin-bottom: 5%;
`
const Img = styled.img`
height:240px;
  width: auto
  margin: 0%;
  cursor: pointer;
`

const DivText = styled.div`
  height: 70px;
  background-color: #f6f9fb;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  margin: -25% -3% -3% 10%;
  display: block;
  .container-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .x{
    width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px;

  }
`
const TextName = styled.p`
  font-family: Archivo;
  font-weight: bold;
  font-size: 1rem;
 // padding: 5px 0px 3px 10px;
  margin: 0;
  flex-grow: 1;
  height: 55%;
  display: flex;
  align-items: center;


`
const TextInfo = styled.p`
  font-family: Archivo;
  font-style: italic;
  font-size: 12px;
//  padding: 0px 10px 10px;
  margin: 0;
  width: 100%;
  line-height: 115%;
  height: 45%;

`

function ProfileCard({ image, name, position, linkedin, linkedinText }) {


  return (
    <DivCard
    
      /* active={props.active === props.id ? true : false} */
    >
      <Img
        src={
          image
        } /* id={props.id} active={props.active === props.id ? true : false} */
      />
      <DivText /* active={props.active === props.id ? true : false} */>
        
          <div className="x">
          
          <TextName>{name}</TextName>
          {position&&
            <TextInfo>{position}</TextInfo>}
          </div>


      </DivText>
    </DivCard>
  )
}
export default ProfileCard
