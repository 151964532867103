import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"

const Text = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  /*   font-family: "Archivo", sans-serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 0.055em;

  @media (max-width: 700px) {
    width: 100%;
  }
`

const HeaderStyled = styled.h1`
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 2.5vw;
  letter-spacing: 0.055em;
  color: #303030;

  @media (max-width: 700px) {
    font-size: 16px;
    width: 80%;
  }
`

const SectionTitle = styled.div`
  display: inline-block !important;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 150.3%;
  width: 100%;
  text-align: left;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #262626;
  @media (max-width: 700px) {
    font-size: 16px;
    width: 80%;
  }
`

const Content = styled.div`
  display: flex;
 
  margin: 0px;
  flex-wrap: wrap;
`

const SectionParagraph = styled.p`
  margin-top: 2rem;
  margin-bottom: 0rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 136.8%;
  letter-spacing: 0.1em;
  color: #767b8a;
  padding-left: 12px;
  padding-right: 25px;
  text-align: justify;
  text-justify: inter-word;
  font-size: ${props => props.fontSize || "16px"};
  @media (min-width: 700px) {
    font-size: 16px;
  }
  @media (min-width: 1050px) {
    font-size: 18px;
  }
  @media (max-width: 900px) {
    letter-spacing: 0;
    margin-top: 1.5rem;
  }
`

function Intro2(props) {
  return (
    <>
      <Content
       
      >
        <Text>
          <SectionTitle>{props.title}</SectionTitle>
          <SectionParagraph fontSize={props.fontSize}>
            {props.children}
          </SectionParagraph>
        </Text>
      </Content>
    </>
  )
}

export default Intro2
